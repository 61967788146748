/*
handles clicks on weitere projekte images (Logos of Projektpartner) if it is touch device
*/


(function() {

  var elements = document.querySelectorAll('.weitere-projekte__list a');

  if (document.querySelector('html').classList.contains('is-touch')) {
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', handleClick);
    }
  }

  function removeClassFromOtherAElements(exceptElement) {
    for (var i = 0; i < elements.length; i++) {
      if (elements[i] !== exceptElement) {
        elements[i].classList.remove('is-hover');
      }
    }
  }

  function handleClick(event) {
    removeClassFromOtherAElements(this);
    if (!this.classList.contains('is-hover')) {
      event.preventDefault();
      this.classList.add('is-hover');
    } else {
      this.classList.remove('is-hover');
    }
  }

})();
