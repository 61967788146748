// homepage
//
// Author: tobias.wolf@wagnerwagner.de


(function(){

  'use strict';

  // elements
  var teaserMosaikHeroElement = document.querySelector('.teaser-mosaik__hero');
  var linkElement, textElement, mainNavigationActiveElements, teaserMosaikElements;

  // init elements
  if (teaserMosaikHeroElement) {
    linkElement = teaserMosaikHeroElement.querySelector('a');
    textElement = teaserMosaikHeroElement.querySelector('.ce_text');
    mainNavigationActiveElements = document.querySelectorAll('.main-navigation li > .active, .main-navigation li > .trail');
    teaserMosaikElements = document.querySelectorAll('.teaser-mosaik');
  }

  // variables
  var url;
  var preventRedirect = true;

  // init variables
  if (teaserMosaikHeroElement) {
    url = linkElement.href;
  }

  // functions
  function openUrl(e) {
    if (e.propertyName === 'width') {
      try {
        mainNavigationActiveElements[0].classList.remove('active');
        mainNavigationActiveElements[0].classList.remove('trail');
        mainNavigationActiveElements[1].classList.remove('active');
        mainNavigationActiveElements[1].classList.remove('trail');
      } catch(error) {
      }
      if (!preventRedirect) {
        location.href = url;
      }
    }
  }
  function makeTransition() {
    var mosaikContainerElement = document.getElementById('mosaik-container');
    window.scrollTo(null, mosaikContainerElement.offsetTop);
    teaserMosaikHeroElement.classList.add('make-transition');
    for(var i = 0; i < teaserMosaikElements.length; i++) {
      teaserMosaikElements[i].classList.add('make-transition');
    }
    teaserMosaikHeroElement.addEventListener('transitionend', openUrl);
  }
  function reset() {
    preventRedirect = true;
    try {
      mainNavigationActiveElements[0].classList.add('active');
      mainNavigationActiveElements[0].classList.add('trail');
      mainNavigationActiveElements[1].classList.add('active');
      mainNavigationActiveElements[1].classList.add('trail');
    } catch(error) {
    }
    teaserMosaikHeroElement.classList.remove('make-transition');
    for(var i = 0; i < teaserMosaikElements.length; i++) {
      teaserMosaikElements[i].classList.remove('make-transition');
    }
  }
  function handleClick(e) {
    if (window.innerWidth > 105 * 7) {
      e.preventDefault();
      preventRedirect = false;
      makeTransition();
    }
  }

  // event listener
  if (linkElement) {
    linkElement.addEventListener('click', handleClick);
  }
  if (teaserMosaikElements) {
    window.addEventListener('popstate', reset);
  }

  // init

})();
