// toggle-navigation
// Show / hide navigation
//
// Author: tobias.wolf@wagnerwagner.de


(function(){

  'use strict';

  function ToggleNavigation(element) {

    // elements
    var mainNavigationElement = document.querySelector('.main-navigation');

    // variables

    // functions
    function show() {
      element.classList.add('is-active');
      mainNavigationElement.classList.add('is-active');
      bodyElement.classList.add('navigation-is-active');
    }
    function hide() {
      element.classList.remove('is-active');
      mainNavigationElement.classList.remove('is-active');
      bodyElement.classList.remove('navigation-is-active');
    }
    function handleClick() {
      if (bodyElement.classList.contains('navigation-is-active') &&
          mainNavigationElement.classList.contains('is-active')) {
        hide();
      } else {
        show();
      }
    }

    // event listener
    element.addEventListener('click', handleClick);

    // init

    // public variables

  }

  // elements
  var toggleNavigationElements = document.querySelectorAll('a.toggle-navigation');
  var bodyElement = document.querySelector('body');

  // variables

  // functions

  // event listener

  // init instances
  for (var i = 0; i < toggleNavigationElements.length; i++) {
    ToggleNavigation(toggleNavigationElements[i]);
  }

})();
