// popup
//
// Author: tobias.wolf@wagnerwagner.de


(function () {

  function Popup(linkElement) {

    var id = linkElement.hash.substr(2);
    var popupElement = document.getElementById(id);

    function handleHashchange() {
      var hash = location.hash.substr(2);
      if (hash === id) {
        popupElement.style.display = 'flex';
        popupElement.offsetWidth;
        popupElement.classList.add('is-open');
        popupElement.style.display = null;
      } else {
        popupElement.classList.remove('is-open');
        popupElement.style.display = 'flex';
        setTimeout(function(){
          popupElement.style.display = null;
        }, 600);
      }
    }

    function handleClick(e) {
      if (e.target === popupElement) {
        location.hash = '#!';
      }
    }

    popupElement.addEventListener('click', handleClick);

    window.addEventListener('hashchange', handleHashchange);

    handleHashchange();

  }

  var popupElements = document.querySelectorAll('a[data-js="popup"]');

  for (var i = 0; i < popupElements.length; i++) {
    new Popup(popupElements[i]);
  }

})();
