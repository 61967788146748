// easing functions http://goo.gl/5HLl8
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) {
    return c/2*t*t + b;
  }
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

Math.easeInCubic = function(t, b, c, d) {
  var tc = (t/=d)*t*t;
  return b+c*(tc);
};

Math.inOutQuintic = function(t, b, c, d) {
  var ts = (t/=d)*t,
  tc = ts*t;
  return b+c*(6*tc*ts + -15*ts*ts + 10*tc);
};

window.scrollTo = function (element, scrollTop, scrollLeft, duration, callback) {
  function move(amount) {
    if (element) {
      if (scrollLeft) {
        element.scrollLeft = amount;
      } else {
        element.scrollTop = amount;
      }
    } else {
      // because it's so fucking difficult to detect the scrolling element, just move them all
      if (scrollLeft) {
        document.documentElement.scrollLeft = amount;
        document.body.parentNode.scrollLeft = amount;
        document.body.scrollLeft = amount;
      } else {
        document.documentElement.scrollTop = amount;
        document.body.parentNode.scrollTop = amount;
        document.body.scrollTop = amount;
      }
    }
  }
  function position() {
    var pos;
    if (element) {
      if (scrollLeft) {
        pos = element.scrollLeft;
      } else {
        pos = element.scrollTop;
      }
    } else {
      if (scrollLeft) {
        pos = document.documentElement.scrollLeft || document.body.parentNode.scrollLeft || document.body.scrollLeft;
      } else {
        pos = document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop;
      }
    }
    return pos;
  }
  var start = position(),
    change = scrollTop - start,
    currentTime = 0,
    increment = 20;
  duration = (typeof(duration) === 'undefined') ? 500 : duration;
  var animateScroll = function() {
    // increment the time
    currentTime += increment;
    // find the value with the quadratic in-out easing function
    var val = Math.easeInOutQuad(currentTime, start, change, duration);
    // move the document.body
    move(val);
    // do the animation unless its over
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      if (callback && typeof(callback) === 'function') {
        // the animation is done so lets callback
        callback();
      }
    }
  };
  animateScroll();
}
