// kontakt
// Handles label
//
// Author: tobias.wolf@wagnerwagner.de


(function(){

  'use strict';

  function Field(element) {

    // elements
    var labelElement = element.parentElement.querySelector('label');

    // variables

    // functions
    function checkIfFilled() {
      if (labelElement) { 
        if (element.value.length > 0) {
          labelElement.classList.add('is-filled');
        } else {
          labelElement.classList.remove('is-filled');
        }
      }
    }
    function handleFocus() {
      labelElement.classList.add('on-focus');
    }
    function handleBlur() {
      labelElement.classList.remove('on-focus');
      checkIfFilled();
    }

    // event listener
    element.addEventListener('focus', handleFocus);
    element.addEventListener('blur',  handleBlur);

    // init
    checkIfFilled();

    // public variables

  }

  // elements
  var fieldElements = document.querySelectorAll('input[type="text"], input[type="email"], input[type="tel"], textarea');

  // variables

  // functions

  // event listener

  // init instances
  for (var i = 0; i < fieldElements.length; i++) {
    Field(fieldElements[i]);
  }

})();
