// fragen
//
//
// Author: tobias.wolf@wagnerwagner.de


(function () {

  'use strict';

  function Frage(element) {

    // elements
    var h2Element = element.querySelector('h2');
    var textElement = element.querySelector('.ce_text__text');
    var aElement = document.createElement('a');

    // variables
    var heading = h2Element.innerText;
    var elementHeight;
    var textHeight;
    var id = window.convertToSlug(h2Element.innerText);

    // functions
    function init() {
      updateTextHeight();
      h2Element.innerText = '';
      aElement.href = location.pathname + '#!' + id;
      aElement.innerText = heading;
      h2Element.appendChild(aElement);
      element.style.transitionDuration = '0s';
      h2Element.style.transitionDuration = '0s';
      textElement.style.transitionDuration = '0s';
      openClose();
      elementHeight = element.offsetHeight;
      element.style.transitionDuration = null;
      h2Element.style.transitionDuration = null;
      textElement.style.transitionDuration = null;
    }
    function open() {
      element.classList.remove('is-close');
      textElement.style.height = textHeight + 'px';
      aElement.href = location.pathname + '#!';
      aElement.setAttribute('role', 'button');
      aElement.setAttribute('aria-expanded', 'true');
      textElement.setAttribute('aria-hidden', 'false');
    }
    function close() {
      element.classList.add('is-close');
      textElement.style.height = null;
      aElement.href = location.pathname + '#!' + id;
      aElement.setAttribute('aria-expanded', 'false');
      textElement.setAttribute('aria-hidden', 'true');
    }
    function show() {
      if (element.classList.contains('is-hidden')) {
        element.style.height = '0';
        element.offsetHeight;
        element.style.height = elementHeight + 'px';
        element.classList.remove('is-hidden');
        element.setAttribute('aria-hidden', 'false');
        setTimeout(function () {
          element.style.height = null;
        }, 310);
      }
    }
    function hide() {
      if (!element.classList.contains('is-hidden')) {
        element.style.height = element.offsetHeight + 'px';
        element.offsetHeight;
        element.classList.add('is-hidden');
        element.offsetHeight;
        element.style.height = null;
        element.setAttribute('aria-hidden', 'true');
      }
    }
    function openClose() {
      var hash = location.hash.substr(2);
      if (hash === id) {
        open();
      } else {
        close();
      }
    }
    function showHide(hash) {
      if (hash.length === 0 || element.classList.contains(hash)) { // hash === '#!' or …
        show();
      } else {
        hide();
      }
    }
    function updateTextHeight() {
      textHeight = textElement.offsetHeight;
    }
    function handleHashChange() {
      openClose();
    }

    // event listener
    window.addEventListener('hashchange', handleHashChange);

    // init
    init();

    // public variables
    this.showHide = showHide;

  }

  // elements
  var elements = document.querySelectorAll('.fragen .ce_accordion .ce_text');
  var ce_headline__aElements;

  // variables
  var hash = location.hash.substr(2);
  var fragen = [];
  var navHashes = []; // hashes of nav items (.fragen .ce_headline a)

  // functions
  function initElements() {
    ce_headline__aElements = document.querySelectorAll('.fragen .ce_headline a');
    for (var i = 0; i < elements.length; i++) {
      fragen.push(new Frage(elements[i]));
    }
  }
  function initNavHashes() {
    for (var i = 0; i < ce_headline__aElements.length; i++) {
      var hash = ce_headline__aElements[i].hash;
      hash = hash.substr(hash.indexOf('#!') + 2);
      navHashes.push(hash);
    }
  }
  function filter() {
    if (navHashes.indexOf(hash) !== -1 || hash.length === 0) { // or hash === '#!'
      for (var i = 0; i < fragen.length; i++) {
        fragen[i].showHide(hash);
      }
    }
  }
  function handleNavIndication() {
    if (navHashes.indexOf(hash) !== -1 || hash.length === 0) {
      for (var i = 0; i < ce_headline__aElements.length; i++) {
        var aElement = ce_headline__aElements[i];
        if (aElement.hash.substr(2) === hash || hash === '') {
          aElement.classList.remove('is-inactive');
        } else {
          aElement.classList.add('is-inactive');
        }
      }
    }
  }
  function handleHashChange(e) {
    e.preventDefault();
    hash = location.hash.substr(2);
    filter();
    handleNavIndication();
  }

  // event listener
  if (elements) {
    window.addEventListener('hashchange', handleHashChange);
  }

  // init
  if (elements) {
    initElements();
    initNavHashes();
    filter();
    handleNavIndication();
  }

})();
