// projektpartner-filter
// Filters Projektpartner by category
//
// Author: tobias.wolf@wagnerwagner.de


(function(){

  'use strict';

  // elements
  var projektpartnerElement = document.querySelector('.projektpartner-overview');
  var overview__filterElement,
      overview_region__filterElement,
      overview__filterAElements,
      overview_region__filterAElements,
      besondereGeschichten__listElement,
      besondereGeschichten__itemElements,
      weitereProjekte__listElement,
      weitereProjekte__itemElements;

  if (projektpartnerElement) {
    overview__filterElement = document.querySelector('.overview__filter:not(.overview__filter_region)');
    overview__filterAElements = overview__filterElement.querySelectorAll('a');

    overview_region__filterElement = document.querySelector('.overview__filter_region');
    overview_region__filterAElements = overview_region__filterElement.querySelectorAll('a');

    besondereGeschichten__listElement = document.querySelector('.besondere-geschichten__list');
    besondereGeschichten__itemElements = besondereGeschichten__listElement.querySelectorAll('.besondere-geschichten__item');

    weitereProjekte__listElement = document.querySelector('.weitere-projekte__list');
    weitereProjekte__itemElements = weitereProjekte__listElement.querySelectorAll('.ce_image');
  }


  // variables
  var visibleBesondereGeschichtenItems,
      visibleWeitereProjekteItems,
      heightOfBesonderGeschichten__itemElement,
      widthOfWeitereProjekte__itemElement,
      heightOfWeitereProjekte__itemElement;

  // functions

  function handleNavIndication() {
    var hash = location.hash;
    if (hash.length > 0) {
      var aElements = Array.prototype.slice.call(overview__filterAElements)
                      .concat(Array.prototype.slice.call(overview_region__filterAElements));
      for (var i = 0; i < aElements.length; i++) {
        var aElement = aElements[i];
        if (aElement.hash === hash || hash === '#!') {
          aElement.classList.remove('is-inactive');
        } else {
          aElement.classList.add('is-inactive');
        }
      }
    }
  }

  function positionElements() {
    // Position Besondere Geschichten
    for (var i = 0; i < visibleBesondereGeschichtenItems.length; i++) {
      var element = visibleBesondereGeschichtenItems[i];
      element.style.position = 'absolute';
      if (window.matchMedia('screen and (min-width: 1280px)').matches) {
        element.style.top = Math.floor(i / 2) * (heightOfBesonderGeschichten__itemElement + 75) + 'px';
        element.style.left = i % 2 * 600 + 'px';
      } else {
        element.style.left = null;
        element.style.top = i * (heightOfBesonderGeschichten__itemElement + 75) + 'px';
      }
    }
    if (window.matchMedia('screen and (min-width: 1280px)').matches) {
      besondereGeschichten__listElement.style.height = (Math.ceil(visibleBesondereGeschichtenItems.length / 2)) * (heightOfBesonderGeschichten__itemElement + 75) + 'px';
    } else {
      besondereGeschichten__listElement.style.height = visibleBesondereGeschichtenItems.length * (heightOfBesonderGeschichten__itemElement + 75) + 'px';
    }
    // Position Weitere Projekte
    for (var i = 0; i < visibleWeitereProjekteItems.length; i++) {
      var element = visibleWeitereProjekteItems[i];
      if (window.matchMedia('screen and (min-width: 1280px)').matches) {
        element.style.position = 'absolute';
        element.style.left = i % 4 * (widthOfWeitereProjekte__itemElement + 75) + 'px';
        element.style.top  = Math.floor(i / 4) * heightOfWeitereProjekte__itemElement + Math.floor(i / 4) * 75 + 29 + 70 + 'px'; // 29 + 70 is the height of ce_headline
      } else if (window.matchMedia('screen and (min-width: 857px) and (max-width: 1279px)').matches) {
        element.style.position = 'absolute';
        element.style.left = i % 3 * (widthOfWeitereProjekte__itemElement + 75) + 'px';
        element.style.top  = Math.floor(i / 3) * heightOfWeitereProjekte__itemElement + Math.floor(i / 3) * 75 + 29 + 70 + 'px'; // 29 + 70 is the height of ce_headline
      } else if (window.matchMedia('screen and (min-width: 632px)').matches) {
        element.style.position = 'absolute';
        element.style.left = i % 2 * (widthOfWeitereProjekte__itemElement + 75) + 'px';
        element.style.top  = Math.floor(i / 2) * heightOfWeitereProjekte__itemElement + Math.floor(i / 2) * 75 + 29 + 70 + 'px'; // 29 + 70 is the height of ce_headline
      } else if (window.matchMedia('screen and (min-width: 459px)').matches) {
        element.style.position = 'absolute';
        element.style.left = i % 2 * (widthOfWeitereProjekte__itemElement + 50) + 'px';
        element.style.top  = Math.floor(i / 2) * heightOfWeitereProjekte__itemElement + Math.floor(i / 2) * 50 + 29 + 70 + 'px'; // 29 + 70 is the height of ce_headline
      } else {
        element.style.position = 'absolute';
        element.style.left = i % 2 * (widthOfWeitereProjekte__itemElement + 16) + 'px';
        element.style.top  = Math.floor(i / 2) * heightOfWeitereProjekte__itemElement + Math.floor(i / 2) * 16 + 29 + 70 + 'px'; // 29 + 70 is the height of ce_headline
      }
    }
    if (window.matchMedia('screen and (min-width: 1280px)').matches) {
      weitereProjekte__listElement.style.height = (Math.ceil(visibleWeitereProjekteItems.length / 4)) * heightOfWeitereProjekte__itemElement + (Math.ceil(visibleWeitereProjekteItems.length / 4)) * 75 + 29 + 70 + 'px';
    } else if (window.matchMedia('screen and (min-width: 857px) and (max-width: 1279px)').matches) {
      weitereProjekte__listElement.style.height = (Math.ceil(visibleWeitereProjekteItems.length / 3)) * heightOfWeitereProjekte__itemElement + (Math.ceil(visibleWeitereProjekteItems.length / 3)) * 75 + 29 + 70 + 'px';
    } else if (window.matchMedia('screen and (min-width: 632px)').matches) {
      weitereProjekte__listElement.style.height = (Math.ceil(visibleWeitereProjekteItems.length / 2)) * heightOfWeitereProjekte__itemElement + (Math.ceil(visibleWeitereProjekteItems.length / 2)) * 75 + 29 + 70 + 'px';
    } else if (window.matchMedia('screen and (min-width: 459px)').matches) {
      weitereProjekte__listElement.style.height = (Math.ceil(visibleWeitereProjekteItems.length / 2)) * heightOfWeitereProjekte__itemElement + (Math.ceil(visibleWeitereProjekteItems.length / 2)) * 50 + 29 + 70 + 'px';
    } else {
      weitereProjekte__listElement.style.height = (Math.ceil(visibleWeitereProjekteItems.length / 2)) * heightOfWeitereProjekte__itemElement + (Math.ceil(visibleWeitereProjekteItems.length / 2)) * 16 + 29 + 70 + 'px';
    }
  }

  function filter() {
    var hash = location.hash.substr(2);
    // Filter Besondere Geschichten
    visibleBesondereGeschichtenItems = [];
    for (var i = 0; i < besondereGeschichten__itemElements.length; i++) {
      var element = besondereGeschichten__itemElements[i];
      if (hash.length === 0 || element.classList.contains(hash)) {
        element.classList.remove('is-hidden');
        visibleBesondereGeschichtenItems.push(element);
      } else {
        element.style.left = null;
        element.style.top  = null;
        element.classList.add('is-hidden');
      }
    }
    // Filter Weitere Projekte
    visibleWeitereProjekteItems = [];
    for (var i = 0; i < weitereProjekte__itemElements.length; i++) {
      var element = weitereProjekte__itemElements[i];
      if (hash.length === 0 || element.classList.contains(hash)) {
        element.classList.remove('is-hidden');
        visibleWeitereProjekteItems.push(element);
      } else {
        element.classList.add('is-hidden');
      }
    }
    positionElements();
  }

  function getWidthAndHeightOfItemElement() {
    heightOfBesonderGeschichten__itemElement = besondereGeschichten__itemElements[0].offsetHeight;
    widthOfWeitereProjekte__itemElement      = weitereProjekte__itemElements[0].offsetWidth;
    heightOfWeitereProjekte__itemElement     = weitereProjekte__itemElements[0].offsetHeight;
  }

  function handleHashChange(e) {
    e.preventDefault();
    filter();
    handleNavIndication();
  }

  function handleResize() {
    getWidthAndHeightOfItemElement();
    positionElements();
  }

  function handleFirstImgLoad() {
    setTimeout(function() {
      getWidthAndHeightOfItemElement();
      positionElements();
    }, 100);
  }

  // event listener
  if (projektpartnerElement) {
    var firstImg = besondereGeschichten__itemElements[0].querySelector('img');
    window.addEventListener('hashchange', handleHashChange);
    window.addEventListener('resize', handleResize);
    try {
      if (firstImg.complete) {
        handleFirstImgLoad();
      } else {
        firstImg.addEventListener('load', handleFirstImgLoad);
      }
    } catch(error) {
      console.log(error);
    }
  }

  // init
  if (projektpartnerElement) {

    overview__filterElement.style.display = 'block';
    overview_region__filterElement.style.display = 'block';

    getWidthAndHeightOfItemElement();
    filter();
    handleNavIndication();
    positionElements();

  }

})();
