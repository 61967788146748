// vorteile
// Shows/hide Vorteile
//
// Author: tobias.wolf@wagnerwagner.de


(function () {

  'use strict';

  function Vorteil(element) {

    // elements
    var buttonElement = element.querySelector('.ce_hyperlink a');
    var headlineElement = element.querySelector('.ce_headline');
    var textElement = element.querySelector('.ce_text');

    // variables
    var id = window.convertToSlug(headlineElement.innerText);
    var buttonText = buttonElement.innerText;
    var isFirst = element.classList.contains('first') ? true : false;

    // functions
    function init() {
      buttonElement.href = location.pathname + '#!' + id;
      buttonElement.setAttribute('role', 'button');
      buttonElement.setAttribute('aria-controls', id);
      buttonElement.setAttribute('aria-expanded', 'false');
      textElement.setAttribute('aria-hidden', 'true');
      textElement.id = id;
    }
    function addHidden() {
      element.classList.add('is-hidden');
      if (isFirst) {
        whiteLogoElement.style.opacity = '0';
      } else {
        whiteNavigationElement.style.opacity = '0';
      }
    }
    function removeHidden() {
      element.classList.remove('is-hidden');
      if (isFirst) {
        whiteLogoElement.style.opacity = null;
      } else {
        whiteNavigationElement.style.opacity = null;
      }
    }
    function show() {
      element.classList.add('is-visible');
      buttonElement.href = location.pathname + '#!';
      buttonElement.innerHTML = 'zurück';
      buttonElement.setAttribute('aria-expanded', 'true');
      textElement.setAttribute('aria-hidden', 'false');
    }
    function hide() {
      element.classList.remove('is-visible');
      buttonElement.href = location.pathname + '#!' + id;
      buttonElement.innerHTML = buttonText;
      buttonElement.setAttribute('aria-expanded', 'false');
      textElement.setAttribute('aria-hidden', 'true');
    }

    // event listener

    // init
    init();

    // public variables
    return {
      addHidden: addHidden,
      removeHidden: removeHidden,
      show: show,
      hide: hide,
      id: id
    };

  }

  // elements
  var element = document.getElementById('vorteile');
  var blueMainNavigationElement = document.querySelector('#header .main-navigation');
  var elements,
    whiteNavigationElement,
    whiteLogoElement;

  // variables
  var instances = [];

  // functions
  function showHide() {
    var hash = location.hash.substr(2);
    for (var i = 0; i < instances.length; i++) {
      var instance = instances[i];
      if (hash.length > 0) {
        if (instance.id === hash) {
          instance.show();
          if (i === 0) {
            // blueMainNavigationElement.classList.add('has-half-white-background');
          }
        } else {
          instance.addHidden();
        }
      } else {
        instance.removeHidden();
        instance.hide();
        // blueMainNavigationElement.classList.remove('has-half-white-background');
      }
    }
  }
  function handleHashChange() {
    showHide();
  }

  // event listener
  window.addEventListener('hashchange', handleHashChange);

  // init instances
  if (element) {
    elements = element.querySelectorAll('.mod_article');
    for (var i = 0; i < elements.length; i++) {
      instances.push(new Vorteil(elements[i]));
    }
    whiteNavigationElement = document.querySelector('#white-font .main-navigation')
    whiteLogoElement = document.querySelector('#white-font .logo')
    showHide();
    document.querySelector('html').classList.add('has-js');
  }

})();
