(function () {

  var blueMainNavigationElement = document.querySelector('#header .main-navigation');
  var whiteFontElement = document.getElementById('white-font');
  var vorteileElement = document.getElementById('vorteile');
  var mosaikContainerElement = document.getElementById('mosaik-container');
  var teaserProjektpartnerElement = document.querySelector('.teaser-projektpartner');
  var teaserProduktElement = document.querySelector('.teaser-produkt');
  var kontaktElement = document.querySelector('.kontaktformular');
  var teaserLandingpageElement = document.querySelector('.teaser-landingpage');
  var mainNavigationElement;

  function setSize() {
    if (whiteFontElement) {
      var top, height;
      if (vorteileElement) {
        top = vorteileElement.offsetTop + 'px';
        height = vorteileElement.offsetHeight + 'px';
      }
      if (mosaikContainerElement) {
        top = mosaikContainerElement.offsetTop + 'px';
        height = mosaikContainerElement.offsetHeight + 'px';
      }
      if (teaserProjektpartnerElement) {
        top = '0px';
        height = teaserProjektpartnerElement.offsetHeight + 'px';
      }
      if (teaserProduktElement && window.innerWidth < 840) {
        top = '0px';
        height = teaserProduktElement.querySelector('.ce_text').offsetHeight + 'px';
      }
      if (teaserLandingpageElement) {
        top = '0px';
        height = teaserLandingpageElement.offsetHeight + 'px';
      }
      if (kontaktElement) {
        if (window.innerWidth > 945) {
          top = '0px';
          height = kontaktElement.offsetHeight + 'px';
        } else {
          top = '0px';
          height = kontaktElement.querySelector('.ce_text').offsetHeight + 'px';
        }
      }
      if (top && height) {
        whiteFontElement.style.top = top;
        whiteFontElement.style.height = height;
        whiteFontElement.style.maxHeight = 'none';
      }
    }
  }
  function showHideMainNavigationElement() {
    if (mainNavigationElement) {
      var scrollY = window.scrollY ? window.scrollY : document.documentElement.scrollTop;
      if (vorteileElement) {
        if (scrollY < vorteileElement.offsetTop - 64) { // 64 = 48 + 16 = height of logo + padding
          whiteFontElement.classList.add('is-hidden');
          blueMainNavigationElement.classList.remove('has-no-white-background')
        } else {
          whiteFontElement.classList.remove('is-hidden');
          blueMainNavigationElement.classList.add('has-no-white-background')
        }
      } else if (mosaikContainerElement) {
        if (scrollY > mosaikContainerElement.offsetHeight) {
          whiteFontElement.classList.add('is-hidden');
          blueMainNavigationElement.classList.remove('has-no-white-background')
        } else {
          whiteFontElement.classList.remove('is-hidden');
          blueMainNavigationElement.classList.add('has-no-white-background')
        }
      } else if (kontaktElement) {
        if (scrollY > kontaktElement.querySelector('.ce_text').offsetHeight) {
          whiteFontElement.classList.add('is-hidden');
          blueMainNavigationElement.classList.remove('has-half-white-background');
          blueMainNavigationElement.classList.remove('has-no-white-background');
        } else if (window.innerWidth > 945) {
          whiteFontElement.classList.remove('is-hidden');
          blueMainNavigationElement.classList.add('has-half-white-background');
        } else {
          whiteFontElement.classList.remove('is-hidden');
          blueMainNavigationElement.classList.add('has-no-white-background');
        }
      } else {
        if (scrollY > window.innerHeight || scrollY >= whiteFontElement.offsetHeight) {
          whiteFontElement.classList.add('is-hidden');
          blueMainNavigationElement.classList.remove('has-no-white-background')
        } else {
          whiteFontElement.classList.remove('is-hidden');
          blueMainNavigationElement.classList.add('has-no-white-background')
        }
      }
      if (teaserLandingpageElement) {
        if (scrollY > teaserLandingpageElement.offsetHeight) {
          whiteFontElement.classList.add('is-hidden');
          blueMainNavigationElement.classList.remove('has-no-white-background')
        } else {
          whiteFontElement.classList.remove('is-hidden');
          if (window.innerWidth > 945) {
            blueMainNavigationElement.classList.add('has-no-white-background')
          }
        }
      }
    }
  }

  // event listener
  if (whiteFontElement) {
    document.addEventListener('scroll', showHideMainNavigationElement);
    window.addEventListener('resize', setSize);
  }

  // init
  if (whiteFontElement) {
    mainNavigationElement = whiteFontElement.querySelector('.main-navigation');
    setSize();
    showHideMainNavigationElement();
  }

})();
