// produkte-filter
// Filters Produkte by category
//
// Author: tobias.wolf@wagnerwagner.de


(function(){

  'use strict';

  // elements
  var produkteElement = document.querySelector('.produkte');
  var aElements,
      overviewElement,
      overview__filterElement,
      thumbListElement,
      thumbList__itemElements;

  if (produkteElement) {
    aElements = document.querySelectorAll('.overview__filter a');
    overviewElement = document.querySelector('.overview');
    overview__filterElement = overviewElement.querySelector('.overview__filter');
    thumbListElement = document.querySelector('.thumb-list');
    thumbList__itemElements = thumbListElement.querySelectorAll('.thumb-list__item');
  }

  // variables
  var visibleThumbListLiElements,
      heightOfThumbList__itemElement,
      margin,
      columns;

  // functions

  function handleNavIndication() {
    var href = location.origin + location.pathname + location.hash;
    var hash = location.hash.substr(2);
    for (var i = 0; i < aElements.length; i++) {
      var aElement = aElements[i];
      if (aElement.href === href || hash === '') {
        aElement.classList.remove('is-inactive');
      } else {
        aElement.classList.add('is-inactive');
      }
    }
  }

  function positionElements() {
    for (var i = 0; i < visibleThumbListLiElements.length; i++) {
      var visibleThumbListLiElement = visibleThumbListLiElements[i];
      visibleThumbListLiElement.style.position = 'absolute';
      if (thumbListElement.classList.contains('logo-thumb-list')) {
        visibleThumbListLiElement.style.top = i * (heightOfThumbList__itemElement + margin) + 20 + 'px';
      } else if (thumbListElement.classList.contains('thumb-list')) {
        visibleThumbListLiElement.style.left = i % columns * (heightOfThumbList__itemElement + margin) + 'px';
        visibleThumbListLiElement.style.top = Math.floor(i / columns) * (heightOfThumbList__itemElement + margin) + 20 + 'px';
      }
      if (i + 1 === visibleThumbListLiElements.length) {
        if (thumbListElement.classList.contains('logo-thumb-list')) {
          thumbListElement.style.height = visibleThumbListLiElements.length * (heightOfThumbList__itemElement + margin) + 'px';
        } else if (thumbListElement.classList.contains('thumb-list')) {
          thumbListElement.style.height = (Math.ceil(visibleThumbListLiElements.length / columns)) * (heightOfThumbList__itemElement + margin) + 'px';
        }
      }
    }
  }

  function filter() {
    var hash = location.hash.substr(2);
    if (hash.length > 0) {
      // filter
      visibleThumbListLiElements = [];
      for (var i = 0; i < thumbList__itemElements.length; i++) {
        var projektpartnerElement = thumbList__itemElements[i];
        if (projektpartnerElement.classList.contains(hash)) {
          // show
          projektpartnerElement.classList.remove('is-hidden');
          visibleThumbListLiElements.push(projektpartnerElement);
        } else {
          // hide
          projektpartnerElement.classList.add('is-hidden');
        }
      }
    } else {
      visibleThumbListLiElements = thumbList__itemElements;
      for (var i = 0; i < thumbList__itemElements.length; i++) {
        thumbList__itemElements[i].classList.remove('is-hidden');
      }
    }
    positionElements();
  }

  function updateSizes() {
    heightOfThumbList__itemElement = thumbList__itemElements[1].offsetHeight;
    if (window.innerWidth < 420) {
      margin = 10;
    } else if (window.innerWidth < 505) {
      margin = 16
    } else if (window.innerWidth < 872) {
      margin = 52;
    } else {
      margin = 105;
    }
    if (window.innerWidth < 766) {
      columns = 2;
    } else {
      columns = 3;
    }
  }

  function handleHashChange(e) {
    e.preventDefault();
    filter();
    handleNavIndication();
  }

  function handleResize() {
    updateSizes();
    positionElements();
  }

  // event listener
  if (overviewElement) {
    window.addEventListener('hashchange', handleHashChange);
    window.addEventListener('resize', handleResize);
  }

  // init instances
  if (produkteElement) {
    overview__filterElement.style.display = 'block';
    updateSizes();
    filter();
    handleNavIndication();
    positionElements();
  }

})();
