// detext-touch-device
//
// Author: tobias.wolf@wagnerwagner.de


(function(){

  if ('ontouchstart' in document.documentElement) {
    document.querySelector('html').classList.add('is-touch');
  }

})();
