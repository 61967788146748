// projektpartner-seite
//
// Author: tobias.wolf@wagnerwagner.de


(function(){

  'use strict';

  function Link(element) {

    var url = element.href;
    var preventRedirect = false;

    function openUrl() {
      if (!preventRedirect) {
        location.href = url;
      }
    }
    function makeTransition() {
      makeTransitionGlobal();
      element.classList.add('make-transition');
      scrollTo(null, 0);
      element.addEventListener('transitionend', openUrl);
    }
    function handleClick(e) {
      preventRedirect = false;
      e.preventDefault();
      makeTransition();
    }
    function reset() {
      preventRedirect = true;
      resetTransitionGlobal();
      element.classList.remove('make-transition');
    }

    element.addEventListener('click', handleClick);
    window.addEventListener('popstate', reset);

  }

  // elements
  var teaserNavigationElement = document.querySelector('.teaser-navigation');
  var teaserNavigationLinkElements,
      modArticleElement,
      mainNavigationActiveElements;

  // init elements
  if (teaserNavigationElement) {
    teaserNavigationLinkElements = teaserNavigationElement.querySelectorAll('a');
    modArticleElement = document.querySelector('#main .mod_article');
    mainNavigationActiveElements = document.querySelectorAll('.main-navigation a.trail');
  }

  // variables

  // init variables

  // functions
  function makeTransitionGlobal() {
    try {
      mainNavigationActiveElements[0].classList.remove('trail');
      mainNavigationActiveElements[1].classList.remove('trail');
    } catch(error) {
      console.log(error);
    }
    teaserNavigationElement.classList.add('make-transition');
    modArticleElement.classList.add('make-transition');
  }
  function resetTransitionGlobal() {
    try {
      mainNavigationActiveElements[0].classList.add('trail');
      mainNavigationActiveElements[1].classList.add('trail');
    } catch(error) {
      console.log(error);
    }
    teaserNavigationElement.classList.remove('make-transition');
  }

  // event listener

  // init
  if (teaserNavigationLinkElements) {
    for (var i = 0; i < teaserNavigationLinkElements.length; i++) {
      new Link(teaserNavigationLinkElements[i]);
    }
  }

})();
